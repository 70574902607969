import React from 'react';
import './button.css'

const OpenLinkComponent = () => {
  // Function to handle opening the URL
  const handleOpenLink = () => {
    const url = "https://drive.google.com/file/d/1kBfl5sl88Y2mlRgvMl1zzaqljOjY23BU/view?usp=drive_link";
    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  return (
    <div>
      {/* Button to trigger link opening */}
      <button className='custom-button ' onClick={handleOpenLink}>Open Google Drive Link</button>
    </div>
  );
};

export default OpenLinkComponent;
