import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = 'https://drive.google.com/file/d/1kBfl5sl88Y2mlRgvMl1zzaqljOjY23BU/view';
  }, [navigate]);

  return null;
}

export default RedirectComponent;
