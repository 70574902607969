import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../assets/kutniti-logo.svg";
import logo2 from "../assets/Beta.svg";
import vector from "../assets/Vector.svg";
import twitter from "../assets/twitter.svg";
import youtube from "../assets/youtube.svg";
import linkedin from "../assets/linkedin.svg";
import Home from "./navbarIcons/Home";
import Global from "./navbarIcons/Global";
import Newspaper from "./navbarIcons/Newspaper";
import Bulb from "./navbarIcons/Bulb";
import About from "./navbarIcons/About";
import Article from "./navbarIcons/Article";

import { useMediaQuery } from "react-responsive";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [strokeHome, setStrokeHome] = useState("black");
  const [strokeGlobal, setStrokeGlobal] = useState("black");
  const [strokeNewspaper, setStrokeNewspaper] = useState("black");
  const [strokeArticle, setStrokeArticle] = useState("black");
  const [strokeBulb, setStrokeBulb] = useState("black");
  const [strokeAbout, setStrokeAbout] = useState("black");
  

  const handleNav = () => {
    setNav(!nav);
  };

  const handle1MouseEnter = () => {
    setStrokeHome("white");
  };

  const handle1MouseLeave = () => {
    setStrokeHome("black");
  };

  const handle2MouseEnter = () => {
    setStrokeGlobal("white");
  };

  const handle2MouseLeave = () => {
    setStrokeGlobal("black");
  };

  const handle3MouseEnter = () => {
    setStrokeNewspaper("white");
  };

  const handle3MouseLeave = () => {
    setStrokeNewspaper("black");
  };

  const handle4MouseEnter = () => {
    setStrokeBulb("white");
  };

  const handle4MouseLeave = () => {
    setStrokeBulb("black");
  };

  const handle5MouseEnter = () => {
    setStrokeAbout("white");
  };

  const handle5MouseLeave = () => {
    setStrokeAbout("black");
  };

  const handle6MouseEnter = () => {
    setStrokeArticle("white");
  };

  const handle6MouseLeave = () => {
    setStrokeArticle("black");
  };

  const shareText = "Check out this awesome pie chart!"; // Change as needed
  const shareUrl = encodeURIComponent("http://localhost:3000/country-view"); // Get the current URL



 

  // const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
  const isLaptop = useMediaQuery({ minWidth: 780 });

  return (
    <div className={!isLaptop ? "shadow-2xl z-50 h-auto top-0 left-0 right-0 bg-white m-5 rounded-lg fixed flex justify-between items-center   px-2 py-2 " : "shadow-xl z-50 h-auto top-0 left-0 right-0 backdrop-blur-[3px] bg-white bg-opacity-100 lg:bg-opacity-90 m-5 rounded-lg fixed flex justify-between items-center   px-2 py-2 "}>
      <Link to="/" className="home-link flex">
        <img src={logo2} alt="logo" className="" />
        {/* <h1 className='w-full text-3xl text-black hover:text-purple-600' >Kutniti Watch</h1>
      <p style={{ color: "rgb(121, 0, 255)"}} className='text-sm p-3'>Beta</p> */}
      </Link>


      <ul className="hidden md:flex ">
      <a href="/" className="cursor-pointer hover:bg-black hover:text-white rounded-full px-3 py-1 flex items-center" onMouseEnter={handle1MouseEnter}
          onMouseLeave={handle1MouseLeave}>
          <li
            className="flex items-center"
          >
            <div className="mr-1">
            <a href="/">
            <Home stroke={strokeHome} />
            </a>
              
            </div>

            <div className="">
              <a href="/">Home</a>
            </div>
          </li>
        </a>

        <a href="/country-view" className="cursor-pointer hover:bg-black hover:text-white rounded-full px-3 py-1 flex items-center" onMouseEnter={handle2MouseEnter}
          onMouseLeave={handle2MouseLeave}>
          <li
            className="flex items-center"
          >
            <div className="mr-1">
            <a href="/country-view">
            <Global stroke={strokeGlobal} />
            </a>
              
            </div>
            <div className="">
              <a href="/country-view">Countries</a>
            </div>
          </li>
        </a>

        <a href="/newspaper-view" className="cursor-pointer hover:bg-black hover:text-white rounded-full px-3 flex items-center" onMouseEnter={handle3MouseEnter}
          onMouseLeave={handle3MouseLeave}>
        <li
          className="flex items-center"
        >
          <div className="mr-1">
            <a href="/newspaper-view">
            <Newspaper stroke={strokeNewspaper} />
            </a>
              
            </div>
            <div className="">
              <a href="/newspaper-view">Newspapers</a>
          </div>
        </li>
        </a>

        <a href="/latestArticles" className="cursor-pointer hover:bg-black hover:text-white rounded-full px-3 flex items-center" onMouseEnter={handle6MouseEnter}
          onMouseLeave={handle6MouseLeave}>
        <li
          className="flex items-center"
        >
          <div className="mr-1">
            <Article stroke={strokeArticle} />
            </div>
            <div className="">
              <a href="/latestArticles">Latest Articles</a>
          </div>
        </li>
        </a>

        <a href="/methodology" className="cursor-pointer hover:bg-black hover:text-white rounded-full px-3 py-1 flex items-center" onMouseEnter={handle4MouseEnter}
          onMouseLeave={handle4MouseLeave}>
          <li
            className="flex items-center"
          >
            <div className="mr-1">
            <a href="/methodology">
            <Bulb stroke={strokeBulb} />
            </a>
              
            </div>
            <div className="">
              <a href="/methodology">Methodology</a>
            </div>
          </li>
        </a>

        <a href="/about" className="cursor-pointer hover:bg-black hover:text-white rounded-full px-3 py-1 flex items-center" onMouseEnter={handle5MouseEnter}
          onMouseLeave={handle5MouseLeave}>
          <li
            className="flex items-center"
          >
            <div className="mr-1">
            <a href="/about">
            <About stroke={strokeAbout} />
            </a>
              
            </div>
            <div className="">
              <a href="/about">About</a>
            </div>
          </li>
        </a>
        <a href="https://drive.google.com/file/d/1kBfl5sl88Y2mlRgvMl1zzaqljOjY23BU/view?usp=drive_link" className="cursor-pointer hover:bg-black hover:text-white rounded-full px-3 py-1 flex items-center" onMouseEnter={handle5MouseEnter}
          onMouseLeave={handle5MouseLeave}>
          <li
            className="flex items-center"
          >
            <div className="mr-1">
            <a href="https://drive.google.com/file/d/1kBfl5sl88Y2mlRgvMl1zzaqljOjY23BU/view?usp=drive_link">
            <About stroke={strokeAbout} />
            </a>
              
            </div>
            <div className="">
              <a href="https://drive.google.com/file/d/1kBfl5sl88Y2mlRgvMl1zzaqljOjY23BU/view?usp=drive_link">Starlink Report</a>
            </div>
          </li>
        </a>
      </ul>
      {isLaptop && (
        <div className="flex">
          <a href="https://twitter.com" className="mx-2">
            <img src={twitter} alt="global " className="m-auto w-8" />
          </a>

          <a href="https://www.linkedin.com/company/kutniti-foundation/" className="mx-2">
            <img src={linkedin} alt="global " className="m-auto w-8" />
          </a>

          <a href="https://www.youtube.com/@kutnitifoundation/featured" className="mx-2">
            <img src={youtube} alt="global " className="m-auto w-8" />
          </a>
        </div>
      )}
      {!isLaptop && (
        <div className="flex ml-4 mr-2">
          <a href="https://twitter.com" className="mx-1">
            <img src={twitter} alt="global " className="m-auto w-8" />
          </a>

          <a href="https://www.linkedin.com/company/kutniti-foundation/" className="mx-1">
            <img src={linkedin} alt="global " className="m-auto w-8" />
          </a>

          <a href="https://www.youtube.com/@kutnitifoundation/featured" className="mx-1">
            <img src={youtube} alt="global " className="m-auto w-8" />
          </a>
        </div>
      )}

      <div onClick={handleNav} className="block md:hidden">
        {nav ? (null) : <AiOutlineMenu size={20} />}
      </div>

      <ul
        className={
          nav
            ? "fixed right-0 top-0 w-[80%] h-full border-l border-l-gray-900  ease-in-out duration-500  backdrop-blur-[10px] bg-opacity-0 font-bold"
            : "ease-in-out duration-500 fixed left-[-100%] h-full"
        }
      >
        <div className="">
          <div onClick={handleNav} className="block md:hidden fixed right-7 top-10 ">
            {nav ? <AiOutlineClose size={20} /> : (null)}
          </div>
          <li className="flex mt-20 p-4 border-b border-gray-600">
            <div className="mr-1 self-center">
              <a href="/">
              <Home stroke={strokeHome} />
              </a>
            </div>
            <a href="/">Home</a>
          </li>
          <li className="flex p-4 border-b border-gray-600">
            <div className="mr-1 self-center">
              <a href="/country-view">
              <Global stroke={strokeGlobal} />
              </a>
            </div>
            <a href="/country-view">Countries</a>
          </li>
          <li className="flex p-4 border-b border-gray-600">
            <div className="mr-1 self-center">
              <a href="/newspaper-view">
              <Newspaper stroke={strokeNewspaper} />
              </a>
            </div>
            <a href="/newspaper-view">Newspapers</a>
          </li>
          <li className="flex p-4 border-b border-gray-600">
            <div className="mr-1 self-center">
              <a href="/latestArticles">
              <Article stroke={strokeNewspaper} />
              </a>
            </div>
            <a href="/latestArticles">Latest articles</a>
          </li>
          <li className="flex p-4 border-b border-gray-600">
            <div className="mr-1 self-center">
              <a href="/methodology">
              <Bulb stroke={strokeBulb} />
              </a>
            </div>
            <a href="/methodology">Methodology</a>
          </li>
          <li className="flex p-4 border-b border-gray-600">
            <div className="mr-1 self-center">
              <a href="/about">
              <About stroke={strokeAbout} />
              </a> 
            </div>
            <a href="/about">About</a>
          </li>
          <li className="flex p-4 border-b border-gray-600">
            <div className="mr-1 self-center">
              <a href="https://drive.google.com/file/d/1kBfl5sl88Y2mlRgvMl1zzaqljOjY23BU/view?usp=drive_link">
              <About stroke={strokeAbout} />
              </a> 
            </div>
            <a href="https://drive.google.com/file/d/1kBfl5sl88Y2mlRgvMl1zzaqljOjY23BU/view?usp=drive_link">Starlink Report</a>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
